import React from 'react';
import './InfoHeader.scss';
export default function InfoHeader({ title, subtitle }) {
    const element = subtitle && <span>{subtitle}</span>;
    return (
        <header className="header-content">
            <div className="header-content__info">
                <h1>
                    {element} {title}
                </h1>
            </div>
        </header>
    );
}
