import React from "react";
import Layout from "component/Layout/Layout";
import Seo from "component/seo";
import SidebarRight from "component/Sidebar/SidebarRight";
import SidebarLeft from "component/Sidebar/SidebarLeft";
import styled from "@emotion/styled";
import { graphql, Link } from "gatsby";
import InfoHeader from "component/InfoHeader/InfoHeader";
import { useSiteUrl } from "utils";
import { Disqus } from "gatsby-plugin-disqus";

const StyledMainContent = styled.section`
  flex-grow: 1;

  .ads-wrapper {
    margin-bottom: 20px;
  }

  b {
    color: var(--color-secondary);
  }

  .introduce {
    margin-bottom: 20px;
  }

  .sections {
    .section {
      display: flex;

      &:not(:last-child) {
        border-bottom: 1px solid #c3c3c3;

        .section-summary::before {
          position: absolute;
          bottom: 0;
          right: -1px;
          transform: translate(50%, 50%);
          content: "";
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background-color: #808626;
        }
      }
      .section-summary {
        max-width: 200px;
        width: 100%;
        flex-shrink: 0;
        padding: 20px;
        border-right: 2px solid #808626;
        position: relative;

        h2 {
          font-size: 1.6rem;
          line-height: 24px;
        }
      }
      .section-items {
        padding: 10px;

        ul li {
          display: flex;
          align-items: center;
          padding: 8px 20px;

          svg {
            margin-right: 16px;
            flex-shrink: 0;
          }

          a {
            color: rgba(37, 38, 94, 0.7);

            &:hover {
              text-decoration: underline;
              color: var(--color-primary);
            }
          }
          span {
            padding-right: 22px;
            font-family: var(--font-family-three);
          }
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    & {
      .sections {
        .section {
          .section-summary {
            max-width: 140px;
            padding: 15px 15px 15px 0;
          }
          .section-items {
            ul li {
              padding: 6px 0 !important;

              a {
                display: -webkit-box !important;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical !important;
                overflow: hidden !important;
              }
            }
          }
        }
      }
    }
  }
`;

const LearnJavascriptPage = ({ data, location }) => {
  const { content } = data;
  const canonicalUrl = `${useSiteUrl()}${location.pathname}`;

  let disqusConfig = {
    url: canonicalUrl,
    identifier: canonicalUrl,
    title: "Học JavaScript cơ bản",
  };

  return (
    <Layout>
      <Seo
        title="Học JavaScript cơ bản"
        image="//images.ctfassets.net/o8rbhyyom3pw/4YzrnBrtY2f9kX7qpAmyWO/78ec8df9b3077b89fd302c53da08f976/javascript-la-gi.webp?h=250"
        titleSample={true}
        canonicalUrl={canonicalUrl}
      />
      <SidebarLeft />
      <StyledMainContent>
        <div className="introduce">
          <InfoHeader title="👨‍🏫 Cùng học JavaScript ✍" />
          <p>
            Xin chào các bạn 😀, dưới đây là các bài viết giúp các bạn{" "}
            <b>học JavaScript</b> mà mình đã dành thời gian tìm hiểu, thực hành
            và đúc kết lại được. Hy vọng các bài viết hướng dẫn dưới đây sẽ giúp
            ích được cho các bạn khi học JavaScript 😁.
          </p>
        </div>
        <div className="sections">
          {content?.sections.map((section, i) => {
            const number = i + 1;

            return (
              <div key={section.id} className="section">
                <div className="section-summary">
                  <h2>{section.lesson}</h2>
                </div>
                <div className="section-items">
                  <div className="module-content">
                    <ul>
                      {section.module.map((item, idx) => {
                        return (
                          <li key={item.id}>
                            {/* {[null].includes(idx) ? (
                              <CgRadioChecked fontSize={22} color="#0556f3" />
                            ) : (
                              <CgRadioCheck fontSize={22} color="#d3dce6" />
                            )} */}
                            <span>{`${number}.${idx + 1}`}</span>&nbsp;
                            <Link to={`/${item.url}/`} title={item.title}>
                              {item.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <p className="text-center">Đang cập nhật thêm</p>
        <br />

        <Disqus config={disqusConfig} />
      </StyledMainContent>
      <SidebarRight />
    </Layout>
  );
};

export const query = graphql`
  {
    content: allContentfulLearnJavaScript(
      sort: { fields: createdDate, order: ASC }
    ) {
      sections: nodes {
        lesson
        module {
          url
          title
          id
        }
        id
      }
    }
  }
`;

export default LearnJavascriptPage;
